.CancelButton {
    border: 2px solid var(--unnamed-color-fe7400);
    border: 2px solid #FE7400;
    border-radius: 6px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-23) var(--unnamed-font-family-roboto) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-fe7400);
    text-align: left;
    font: normal normal medium 19px/23px Roboto;
    letter-spacing: 0px;
    color: #FE7400 !important;
    text-transform: capitalize;
    margin: 5px;
}

.SaveButton:hover {
    background: #ee6c01 0% 0% no-repeat padding-box;
}

.SaveButton {
    background: var(--unnamed-color-fe7400) 0% 0% no-repeat padding-box;
    border: 2px solid var(--unnamed-color-fe7400);
    background: #FE7400 0% 0% no-repeat padding-box;
    border: 2px solid #FE7400;
    border-radius: 6px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-23) var(--unnamed-font-family-roboto) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal medium 19px/23px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    text-transform: capitalize;
    margin: 5px;
}

.SaveButton:hover {
    background: #ee6c01 0% 0% no-repeat padding-box;
}

.Title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-medium) var(--unnamed-font-size-15)/var(--unnamed-line-spacing-18) var(--unnamed-font-family-roboto) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(---3b3b3b);
    text-align: left;
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: #3B3B3B;
    opacity: 1;
}

.TextField {
    width: 100%;
}

.ToolbarTitle {
    font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-19)/var(--unnamed-line-spacing-23) var(--unnamed-font-family-roboto) !important;
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-30a4dd);
    text-align: left;
    font: normal normal bold 19px/23px Roboto;
    letter-spacing: 0px;
    color: #30A4DD;
    opacity: 1;
    margin: 10px 0px;
}





/* NEW STYLES */
.parentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px, rgba(0, 0, 0, 0.25) 0px 0px 2px;
    opacity: 1;

}

.backButton{
    border: none;
    background-color: white;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat-Bold";
    align-self: flex-start;
    padding: 0;
}

.infoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 50px;
}

.titleLogoContainer{
    display: flex;
    flex-direction: column;
}

.imageElement{
    width: 200px;
    background-color: lightgray;
}

.infoDataContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}


.textDescription{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat-Light';
    padding: 0;

}
.inputStackContainer{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;

}

.inputContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
}
.subsidiaryNameContainer{
    width: 100%;
    margin-top: 10px;
}

.subsidiaryName{
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    margin: 0;
}
.labelInput{
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    margin: 0;
    width: 25%;

}

.inputElement{
    padding: 10px;
}

.buttonsContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}
.confirmButton{
    background-color: #16b;
     background-image: linear-gradient(#2d8cec, #16b);
     border-radius: 4px;
     border: 1px solid #16b;
     margin: 0px;
     font-family: "Montserrat-Medium";
    
       font-weight: 700;
       font-size: 14px;
       line-height: 24px;
       color: white;
       padding: 10px;
       text-align: center;
       vertical-align: middle;
       cursor: pointer;
}

.cancelButton{
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    margin: 0px;
    font-family: "Montserrat-Medium";
   
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: black;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;

}
.deleteButton{
    background-color: white;
     border-radius: 4px;
     border: 1px solid red;
     margin: 0px;
     font-family: "Montserrat-Medium";
    
       font-weight: 700;
       font-size: 14px;
       line-height: 24px;
       color: red;
       padding: 10px;
       text-align: center;
       vertical-align: middle;
       cursor: pointer;
}



   .confirmButton:focus, .confirmButton:hover,   .cancelButton:focus, .cancelButton:hover,   .deleteButton:focus, .deleteButton:hover {
     opacity: 0.6;
   }
   .confirmButton:disabled, .confirmButton:disabled,   .cancelButton:disabled, .cancelButton:disabled,   .deleteButton:disabled, .deleteButton:disabled  {
    cursor: not-allowed;
    opacity: .5;
   }