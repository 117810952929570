.root {
  display: flex;
  max-width: 1920px;
  margin: 0 auto;
}

.toolbar {
  padding-right: 24px !important;
}

.progressContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1300;
}

.appBar {
  z-index: 20 !important;
}

.title {
  flex-grow: 1 !important;
  color: #000 !important;
}

.drawerPaper {
  position: relative !important;
  white-space: break-spaces;
  width: 270px !important;
  transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  background-color: #122526 !important;
}

.drawerPaperClose {
  overflow-x: hidden !important;
  width: 0 !important;
}

.content {
  height: 84vh;
  padding: 24px;
  flex-grow: 1;
  margin-top: 5%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
}

.chartContainer {
  margin-left: -22px;
}

.tableContainer {
  height: 320px;
}

.h5 {
  margin-bottom: 2px;
}

.link {
  color: #fff !important;
  text-transform: inherit !important;
  color: white;
  font-size: 12px;
  /* font-family: "Montserrat-Light !important"; */
  font-weight: 700;
  /* line-height: 1.43px; */
  margin: 0;
}

.active {
  color: #122526 !important;
}

.activeLable {
  border-bottom: 2px solid;
  color: #fff !important;
}

.secondaryList {
  top: 61%;
}

.avatar {
  background-color: #eee !important;
  color: #122526 !important;
}

.nested {
  padding-left: 20% !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.subListNested {
  padding-left: 35% !important;
}

.dashboardContent {
  height: 84vh;
  padding: 24px;
  flex-grow: 1;
  margin-top: 5%;
  overflow-y: auto;
  background: linear-gradient(-5deg, rgb(255, 255, 255) 58%, #122526 58%);
}
.menuButton {
  margin-left: 12px !important;
  margin-right: 36px !important;
  color: #fff !important;
}

.menuButtonHidden {
  display: none !important;
}
.NavigateToShop,
.Subsidiary {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-15) /
    var(--unnamed-line-spacing-20) var(--unnamed-font-family-roboto) !important;
  letter-spacing: var(--unnamed-character-spacing-0) !important;
  color: #fff !important;
  text-align: left;
  font: normal normal medium 17px/20px Roboto;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  text-transform: initial !important;
}

.NavigateToShop:hover {
  text-decoration: none;
  color: var(--unnamed-color-fe7400) !important;
}

.NotFoundText {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) 25px / var(--unnamed-line-spacing-20)
    var(--unnamed-font-family-roboto) !important;
  color: #000 !important;
  text-align: center;
  letter-spacing: 0px;
  opacity: 1;
  text-transform: initial !important;
}

.notPaddingContent {
  padding: 0 !important;
}

.footerGridContainer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 10px;
  padding: 10px;
  background: #122526;
  z-index: 1200;
  height: 90px;
  vertical-align: middle;
  max-width: fit-content;
  margin: 0 auto;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
}

.footerGridContainer > div {
  text-align: center;
  padding: 0;
  margin: auto 0;
  font-size: 30px;
}

.footerCancelButton {
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4.71014px;
  margin: 0 40px 0 0;
}

.footerCancelButtonText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
  margin: 10px 25px;
  font-family: "Montserrat";
}

.footerSaveButtonText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 10px 25px;
  font-family: "Montserrat";
}

.footerSaveButton {
  width: auto;
  height: auto;
  background-color: #122526;
  border-radius: 4.71014px;
  margin: 0 40px 0 0;
  border: 1px solid white;
}

.footerInformation {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
  text-align: left;
  height: 40px;
  font-family: "Montserrat-Medium";
  padding-left: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-line-clamp: 2;
}

@media only screen and (min-width: 1200px) {
  .paymentBannerLink {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none !important;
  }
  .paymentBannerLink:hover {
    text-decoration: underline !important;
    color: #fff !important;
  }
  .paymentBannerLinkContainer {
    background-color: red;
    padding: 3px;
  }
  .appBarShift {
    margin-left: 240px !important;
    transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  }
  .drawerResponsive_Md_Sm {
    display: none !important;
  }
  .toolbarIcon {
    padding: 10px;
    color: white;
    text-align: center;
  }
  .subsidiaryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1200px) {
  .appBarShift {
    transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  }
  .drawerResponsiveLg {
    display: none !important;
  }

  .paymentBannerLink {
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    text-decoration: none !important;
  }
  .paymentBannerLink:hover {
    text-decoration: underline !important;
    color: #fff !important;
  }
  .paymentBannerLinkContainer {
    background-color: red;
    padding: 3px;
  }
  .toolbarIcon {
    padding: 10px;
    color: white;
    text-align: center;
  }
  .subsidiaryContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (max-width: 750px) {
  .appBarShift {
    transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  }
  .drawerResponsiveLg {
    display: none !important;
  }

  .paymentBannerLink {
    color: #fff;
    font-weight: 700;
    font-size: 9px;
    text-decoration: none !important;
  }
  .paymentBannerLink:hover {
    text-decoration: underline !important;
    color: #fff !important;
  }
  .paymentBannerLinkContainer {
    background-color: red;
    padding: 3px;
  }
  .toolbarIcon {
    padding: 10px;
    color: white;
    text-align: center;
  }
  .subsidiaryContainer {
    display: none;
  }
}
