.uploadImgIcon {
    width: 100%;
    height: 100%;
}

.CSVReaderLabel {
    display: flex;
    margin: 0;
}

.CSVReaderLabel:hover {
    text-decoration: none !important;
}

.addButton {
    width: 100%;
    height: 187px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px dashed #C9C9C9;
    opacity: 1;
    margin: 10px auto;
    padding: 5px;
}

.addButton:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.addFontButton {
    height: 38px;
    border-radius: 0px .25rem .25rem 0 !important;
    width: 100%;
    background: #6c757d 0% 0% no-repeat padding-box;
    border: 1px solid #C9C9C9;
    opacity: 1;
    color: #000 !important;
    text-transform: capitalize;
}

.addFontButton:hover {
    background: #545b61 0% 0% no-repeat padding-box;
}

.imageList {
    width: 100%;
    height: 450px;
    border: 0.368766px solid #000000;
}

.addMore {
    top: 338px;
    left: 605px;
    width: 28px;
    height: 29px;
    opacity: 1;
    color: #9d9d9d;
}

.imageContainer {
    width: 100%;
    height: 187px;
    margin: 5px;
}

.input {
    display: none;
}

.subtitle {
    font-size: 16rem;
    font-weight: 400;
    color: #000 !important;
}

.margin {
    margin: 5px 0;
}

.textField {
    width: 100%;
    background: #fff;
    margin: 0;
}

.imgListContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: hidden;
    background-color: #fff;
    margin: 5px;
}

.dialog {
    min-width: 650px !important;
}

.ImgButton {
    cursor: pointer;
    width: 100%;
    height: auto;
}


.label {
    width: 100%;
}

.buttonTitle {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    margin-top: 0px;
    height: auto;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.buttonSubTitle {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    margin-top: 0px;
    height: 30px;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.button {
   /*  width: auto;
    height: auto; */
    background-color: rgba(255, 255, 255, 0.7) !important;
    border-radius: 4.71014px;
    border: 1px solid #122526 !important;
    margin: 0px;
    font-family: "Montserrat-Medium" !important;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 24px !important;
      color: #000000 !important;
      text-transform: capitalize !important;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      padding: 10px;
      user-select: none;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      cursor: pointer;
  }
  .button:focus, .button:hover {
    opacity: 0.6;
  }