.paper {
  padding: 12px 8px 19px 12px;
  border-radius: 10px !important;
  box-shadow: 0 2px 14px 0 rgba(171, 171, 171, 0.5) !important;
  background-color: #ffffff !important;
}

.paperWithoutUser {
  height: 70px;
}

.name {
  font-family: 'DMSans-Medium' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  text-align: justify;
}

.button {
  width: 95%;
  height: 39px;
  margin: 5px 0 0;
  background-color: #000000 !important;
  border-radius: unset !important;
  min-width: 180px;
}

.whiteButton {
  width: 95%;
  height: 39px;
  margin: 16px 0 0;
  background-color: #fff !important;
  border-radius: unset !important;
  border: none !important;
}

.button:focus {
  border-radius: 0px !important;
}

.textButton {
  width: 93px;
  height: 16px;
  font-family: "DMSans-Medium" !important;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #ffffff !important;
}

.whiteTextButton {
  width: 93px;
  height: 16px;
  font-family: "DMSans-Medium" !important;
  font-size: 12px;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #122526 !important;
}