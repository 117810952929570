.CardContainer,
.BlueCardContainer {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px, rgba(0, 0, 0, 0.25) 0px 0px 2px;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto 0 auto;
    padding: 2%;
}

.BlueCardContainer {
    background: #F2F5FB !important;
    border: 0.831362px solid #8A8A8C;
    padding: 0;
    margin: 15px 0;
    border-radius: 0;
}

.TitleToolbar {
    min-width: 100px;
    height: auto;
    opacity: 1;
    font-size: 28px;
    margin: 0px;
    text-transform: capitalize;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}


.SubTitleToolbar {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #7A7A7A;
    margin-bottom: 5px;
}

.blueCardTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: auto 0;
    width: 100%;
}

.blueCardTitleContainer {
    min-height: 40px;
    padding: 15px 25px;
    justify-content: initial;
    display: flex;
    position: relative;
    align-content: center;
    margin: 0;
    width: 100%;
}

.blueCardContentContainer {
    background: #fff;
    padding: 35px 25px 15px 25px;
}

.toolbarContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
}

.firstSecondComponent{
    
    margin-bottom: 5px;

}
.componentsContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

@media only screen and (min-width: 1200px) {
    .toolbarTitlesContainer{
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }
    .toolbarContainer{
        display: flex;
    }
   
}
@media only screen and (min-width:751px) and (max-width: 1200px)  {
    .toolbarContainer{
        display: flex;
    }
    .toolbarTitlesContainer{
        display: flex;
        flex-direction: column;
    }
    
}
@media only screen and (max-width: 750px) {
    .toolbarContainer{
        display: flex;
        flex-direction: column;
    }
    .toolbarTitlesContainer{
        display: flex;
        flex-direction: column;
    }
    
}