.calendlyItem {
    font-size: 12px;
    margin: 5px;
    color: #000;
}

.calendlyFree {
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    color: #000;
}

.calendlyAgenda {
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    color: #000;
}

.especialista {
    margin: auto;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.asesoria {
    text-align: center;
    font-size: 12px;
    color: #000;
}

.title {
    font-family: 'Montserrat';
    font-weight: normal;
    font-size: 22px;
    line-height: 20px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

.description {
    font-family: 'Montserrat-Light';
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    margin: 5px 0px;
}

.note {
    font-family: 'Montserrat-Light';
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    margin: 0px 0px 5px 0px;
}

.back {
    font-family: 'Montserrat';
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    color: #fff !important;
    cursor: pointer;
    margin: 0;
}

.resolutionGridContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    padding: 0px;
}

.resolutionGridContainer>div {
    padding: 0px;
}

.resolutionButton {
    background: #FFFFFF;
    width: 120px;
    height: 120px;
    border: 0.3px solid #fff;
    margin: 25px 0px;
    cursor: pointer;
}

.resolutionButtonSelected {
    border: 1px solid #3883E2;
}

.resolutionButton:hover {
    background: #122526;
}

.resolutionButtonText {
    font-family: 'Montserrat-Light';
    font-size: 6px;
    line-height: 7px;
    text-align: center;
    color: #fff;
}

.resolutionText {
    font-family: 'Montserrat-Light';
    font-size: 16px;
    text-align: center;
    color: #fff !important;
}

.imgButton {
    width: 35px;
    height: 35px;
    margin: 10px auto;
    display: flex;
}

.resolutionButton:hover>.resolutionText {
    color: #ffffff9d;
}

.resolutionText {
    font-family: 'Montserrat-Light';
    font-size: 16px;
    text-align: center;
    color: white;
}


.typeSelectionText {
    font-family: 'Montserrat-Light';

    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.informationSelectionText {
    font-family: 'Montserrat-Light';
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    width: 300px;
    margin: 25px 0px 10px 0px
}


.inputGridContainer {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 10px;
    padding: 0px;
    max-width: 253px;
}

.inputGridContainer>div {
    padding: 0px;
}


.input {
    border-color: rgba(0, 0, 0, 0.23);
    padding: 2.5px 5px !important;
    text-align: center;
    font-family: 'Montserrat-Light';
    font-size: 14px;
    line-height: 10px;
    color: #fff;
    width: 60px;
}

.input:focus-visible,
.input:hover {
    color: #fff;
}

.marginHelperText {
    flex-grow: 1;
    font-size: 12px;
    margin: 8px 0;
    text-align: left;
    font-family: 'Montserrat-Light';
    line-height: 10px;
    color: #fff;
}