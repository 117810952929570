.EditorContainer {
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.Tabs {
    width: 99%;
}

.Tab {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 11px !important;
    color: #000000 !important;
    font-family: 'Montserrat-Medium' !important;
}

.slickList {
    width: 100%;
}

.ListDND {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 20rem;
    /* border: 0.1px solid darkgray; */
    overflow-x: hidden;
}

.ButtonGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectedButton {
    color: #fff !important;
    background-color: #122526 !important;
}

.sidenav {
    width: 130px;
    position: fixed;
    z-index: 1;
    top: 20px;
    left: 10px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
}

.managerPanel {
    background-color: #fff;
    position: sticky;
    display: inline-block;
    top: -1px;
    width: 100%;
}

.Accordion {
    width: 101%;
}

.titleContainerManagerPanel {
    height: 45px;
    padding: 5px;
    background-color: #fff;
    border-bottom: 1px solid gray;
}

.containerComponent {
    overflow-y: auto;
}

.InfoContainer {
    /* max-height: 76%; */
    width: 100%;
    /* height: 65vw; */
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 5px 10px 5px;
    position: sticky;
    display: inline-block;
    max-width: 100%;
    top: -1px;
}

.InfoContainer>div {
    width: 99%;
}

.titleManagerPanel {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1000;
    font-family: "Montserrat-Medium" !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal;
    color: #122526 !important;
    padding: 5px 0 !important;
    margin: auto;
    border: 0px !important;
    margin-left: 20px;
}

.containerStatic {
    position: static;
}

.containerFixed {
    position: fixed;
    width: 30%;
}

.componentContainer {
    width: 100%;
    height: auto;
}

/* .componentContainer:hover {
    background-color: #1225262a !important;
    opacity: 0.3;
} */

.availableComponent {
    height: 90px;
    border-radius: 5px;
    margin: 2px;
    width: 100%;
    max-width: 90px;
    max-height: 90px;
    background: #F0F0F0;
    box-shadow: 0px 3.40278px 3.40278px rgba(0, 0, 0, 0.25);
    display: inline-grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
}

.availableComponentImg {
    justify-content: center;
    display: flex;
    align-items: center;
}

.availableComponent:hover {
    /* box-shadow: rgba(7, 7, 8, 0.2) 0px 2px 6px; */
    /* border: 1px solid #12252617; */
    padding: 5px;
}

.titleComponentContainer {
    background-color: transparent;
    top: 15px;
    display: flex;
    position: absolute;
    z-index: 2;
}

.titleComponent {
    background-color: #122526;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    top: 0px;
    z-index: 2;
    width: auto;
}

.deleteComponentContainer {
    background-color: transparent;
    top: 15px;
    display: flex;
    position: absolute;
    z-index: 2;
    right: 15px;
}

.deleteComponent {
    background-color: #122526;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
    top: 0px;
    right: -1px;
    z-index: 2;
    width: auto;
}

.hoverComponent:hover {
    outline: 2px solid #0f7fff !important;
    outline-offset: 1px !important;
    cursor: pointer;
    position: relative;
}

.selectedAlign {
    background-color: #122526c0 !important;
}

/* .componentTextFieldMargin {
    border-radius: 0px !important;
} */

.input1 {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px 0px 1px 1px;
    padding: 2.5px 5px !important;
    height: 29px;
    border-radius: 5px 0px 0px 5px !important;
    text-align: center;
}

.input1:hover,
.input2:hover,
.input3:hover,
.input4:hover {
    border-color: rgb(0, 0, 0);
    border-width: 1px;
}

.input1:focus-visible,
.input2:focus-visible,
.input3:focus-visible,
.input4:focus-visible {
    outline-color: #122526 !important;
    font-weight: 600;
}



.input2 {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px 0px 1px 1px;
    padding: 2.5px 5px !important;
    height: 29px;
    text-align: center;
}

.input3 {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px 0px 1px 1px;
    padding: 2.5px 5px !important;
    height: 29px;
    text-align: center;
}

.input4 {
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px 1px 1px 1px;
    padding: 2.5px 5px !important;
    height: 29px;
    text-align: center;
    border-radius: 0px 5px 5px 0px !important;
}


.EditText,
.EditText:focus,
.EditText:hover,
.EditText:focus-visible {
    border-radius: 0px !important;
    border: none;
    background-color: rgba(89, 89, 89, 0.034);
    outline: none;
}


.grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    padding: 10px;
}

.grid-container>div {
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}

.availableComponentsGridContainer {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 15px;
    padding: 0px;
}



.footerGridContainer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-gap: 10px;
    padding: 10px;
    background: #D1DAEB;
    z-index: 2;
    height: 90px;
    vertical-align: middle;
    max-width: fit-content;
    margin: 0 auto;
    min-width: -webkit-fill-available;
    min-width: -moz-available;
}

.footerGridContainer>div {
    text-align: center;
    padding: 0;
    margin: auto 0;
    font-size: 30px;
}

.footerCancelButton {
    width: auto;
    height: auto;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 4.71014px;
    margin: 0 40px 0 0;
}

.footerCancelButtonText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    text-transform: capitalize;
    margin: 10px 25px;
    font-family: 'Montserrat';
}

.footerSaveButtonText {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 10px 25px;
    font-family: 'Montserrat';
}

.footerSaveButton {
    width: auto;
    height: auto;
    background-color: #122526;
    border-radius: 4.71014px;
    margin: 0 40px 0 0;
}

.footerInformation {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    text-align: left;
    height: 40px;
    font-family: 'Montserrat-Light';
    padding-left: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-line-clamp: 2;
}

.gridContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    padding: 5px;
}

.gridContainer>div {
    padding: 0px;
    margin: auto;
}

.moduleWebgridContainer {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-gap: 5px;
    padding: 5px;
}

.moduleWebgridContainer>div {
    padding: 0px;
    margin: 0;
}

.handContainer {
    display: block;
    position: absolute;
    background-color: #f4f4f4a1;
    z-index: 2;
    height: 100%;
    top: 17%
}

.handLabel {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
}

.addComponentTitleButton {
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    color: #122526;
    font-family: 'Montserrat-Light';
}

.handText {
    font-family: 'Montserrat-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 40px;
    color: #7A7A7A;
    margin: 0;
    padding: 0;
    display: inline-flex;
}

.componentTypeTitle {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 40px;
    color: #000;
    margin: 0;
    padding: 0;
    display: inline-flex;
}

.loadingContainer {
    display: flex;
    position: relative;
    top: 45%;
    justify-content: center;
    min-height: 45vh;
}