/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

/***** Julia *****/

/*---------------*/

.intermitente {
  animation: infinite resplandorAnimation 3s;
}

.animation {
  background: #fbfdff;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@keyframes resplandorAnimation {
  0%,
  100% {
    /* border-color: inherit; */
  }
  50% {
    border-color: #cccbcb;
  }
}

@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dynamicFontClass {
  font-family: "dynamicFont" !important;
  margin: var(--dynamic-margin) !important;
  padding: var(--dynamic-padding) !important;
  font-weight: var(--dynamic-font-weight) !important;
  letter-spacing: var(--dynamic-letter-spacing) !important;
  color: var(--dynamic-color) !important;
}

:root {
  --dynamic-margin: 0px;
  --dynamic-padding: 0px;
  --dynamic-font-weight: 400;
  --dynamic-letter-spacing: normal;
  --dynamic-color: #000;
}

.editLabel {
  cursor: pointer;
  font-weight: 600;
  color: #0081c7;
}

.removeLabel {
  cursor: pointer;
  font-weight: 600;
  color: red;
}

.removeLabel:hover,
.editLabel:hover {
  text-decoration: underline;
}

figure.effect-julia {
  background: transparent;
  border-radius: 15px;
}

figure.effect-julia img {
  max-width: none;
  height: 250px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 5px;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: transparent;
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0);
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.imageloader-loaded {
  display: flex;
}

.slidesSlickList > .slick-list {
  width: 100%;
}

body {
  position: inherit;
  color: black;
  background-color: #fff;
  background-image: #fff;
  overflow-y: hidden;
}

.section {
  margin: auto 0;
}

.slick-next {
  right: 0px !important;
  color: #000;
  top: -10%;
}

.slick-prev {
  left: 0px !important;
  color: #000;
  top: -10%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #122526;
  outline: none;
  background: #00000012;
  border-radius: 20px;
}

.muicc-colorbox-inputs {
  display: none !important;
}

.slick-list {
  width: 90%;
  margin: 0 auto !important;
  max-width: 1200px;
}

.cancelButton {
  text-transform: capitalize !important;
  color: white !important;
  background: red !important;
  font-weight: 700 !important;
}

.cancelButton:hover {
  background: rgb(218, 2, 2) !important;
}

.nextButton {
  text-transform: capitalize !important;
  color: white !important;
  background: green !important;
  font-weight: 700 !important;
}

.nextButton:hover {
  background: rgb(1, 104, 1) !important;
}

.nextButton:disabled,
.cancelButton:disabled,
.addButton:disabled,
.backButton:disabled {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.addButton {
  text-transform: capitalize !important;
  color: white !important;
  border: 2px solid #122526 !important;
  background: #122526 !important;
  font-weight: 400 !important;
  margin: 5px !important;
}

.addButton:hover {
  background: #1a232e !important;
}

.addVariantButton {
  background: var(--unnamed-color-fe7400) 0% 0% no-repeat padding-box;
  border: 2px solid var(--unnamed-color-fe7400);
  background: #fe7400 0% 0% no-repeat padding-box;
  border: 2px solid #fe7400;
  border-radius: 6px;
  opacity: 1;
}

.backButton {
  border: 2px solid #122526 !important;
  background: #fff 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1;
  text-transform: capitalize !important;
  color: #122526 !important;
  margin: 5px !important;
}

.backButton:hover {
  background: #12252630 0% 0% no-repeat padding-box !important;
}

.blueEditionCancelButton,
.blueEditionCancelButton2,
.blueEditionCancelButtonWithoutBorder {
  width: auto;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-radius: 4.71014px !important;
  border: 1px solid #122526 !important;
  margin: 0 10px;
}

.blueEditionCancelButtonWithoutBorder {
  border: none !important;
  border-radius: 0 !important;
}

.blueEditionCancelButton2 {
  border: 1.2604px solid #ffffff !important;
  border-radius: 3.62841px !important;
  background: #122526 !important;
}

.blueEditionCancelButtonText,
.blueEditionCancelButtonText2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
  margin: 5px 10px;
  font-family: "Montserrat";
  font-weight: 700;
  text-decoration: none !important;
}

.blueEditionSaveButtonText:hover,
.blueEditionSaveButtonText2:hover,
.blueEditionCancelButtonText:hover,
.blueEditionCancelButtonText2:hover {
  text-decoration: none !important;
}

.blueEditionCancelButtonText2 {
  color: #fff !important;
}

.blueEditionSaveButtonText,
.blueEditionSaveButtonText2,
.blueEditionDeleteButtonText,
.blueEditionDeleteButtonText2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  margin: 5px 10px;
  font-family: "Montserrat";
  font-weight: 700;
}

.blueEditionSaveButtonText2 {
  color: #122526 !important;
}

.blueEditionDeleteButtonText2 {
  color: #e31b1b !important;
}

.blueEditionSaveButton,
.blueEditionSaveButton2,
.blueEditionDeleteButton,
.blueEditionDeleteButton2 {
  width: auto;
  height: auto;
  background-color: #122526 !important;
  border-radius: 4.71014px !important;
  margin: 0 10px;
}

.blueEditionDeleteButton {
  background: #e31b1b !important;
}

.blueEditionSaveButton2,
.blueEditionDeleteButton2 {
  background: #ffffff !important;
  border-radius: 3.62841px !important;
}

.blueEditionDeleteButton2 {
  border: 1px solid #e31b1b !important;
}

.blueEditionSaveButton:hover {
  background-color: #3c4d62 !important;
}

.blueEditionCancelButtonWithoutBorder:hover {
  background-color: #fff;
}

.blueEditionCancelButton:hover {
  background-color: #12252635 !important;
}

.blueEditionDeleteButton:hover {
  background-color: #e31b1bb9 !important;
}

.blueEditionDeleteButton2:hover {
  background-color: #ff8080 !important;
}

.blueEditionDeleteButton2:hover > .blueEditionDeleteButtonText2 {
  color: #fff !important;
}

.blueEditionSaveButton2:hover {
  background-color: #ffffffad !important;
}

.blueEditionCancelButton2:hover {
  background-color: #3c4d62 !important;
}

.sketch-picker {
  position: relative;
}

.colorInputText {
  font-family: "Montserrat-Light";
  font-size: 12px;
  line-height: 15px;
  color: #7a7a7a;
  width: 100% !important;
}

.colorInput {
  height: 40px;
}

.addMenu {
  font-family: "Montserrat";
  font-size: 13px;
  line-height: 20px;
  color: #3883e2;
  cursor: pointer;
  width: 165px;
  font-weight: 600;
}

.addMenu:hover {
  text-decoration: underline;
}

.inputText {
  font-family: "Montserrat-Light";
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}

/*-- Chrome and Safari --*/
::-webkit-input-placeholder {
  color: #4c4c4c;
}

/*-- Mozilla Firefox --*/
::-moz-placeholder {
  color: #4c4c4c;
}

:-moz-placeholder {
  color: #4c4c4c;
  opacity: 1;
}

/*-- Internet Explorer --*/
::-ms-input-placeholder {
  color: #4c4c4c;
}

/*-- For All Modern Browsers --*/
::placeholder {
  color: #4c4c4c;
}

::placeholder {
  color: #4c4c4c;
}

.inputText::-webkit-.inputText-placeholder {
  color: #4c4c4c;
}

.inputText:-moz-placeholder {
  /* Firefox 18- */
  color: #4c4c4c;
}

.inputText::-moz-placeholder {
  /* Firefox 19+ */
  color: #4c4c4c;
}

.inputText:-ms-.input-placeholder {
  color: #4c4c4c;
}

.inputText::placeholder {
  color: #4c4c4c;
}

textarea::-webkit-input-placeholder {
  color: #4c4c4c;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #4c4c4c;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #4c4c4c;
}

textarea:-ms-input-placeholder {
  color: #4c4c4c;
}

textarea::placeholder {
  color: #4c4c4c;
}

.newEditionValue {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.title {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 20px 0;
}

.inputTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
  margin: 10px 5px 5px 5px;
}

.noteText,
.noteLink,
.noteOptional {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7a7a7a;
  display: inline;
}

.noteLink {
  color: #3883e2 !important;
  cursor: pointer;
}

.noteOptional {
  color: #7a7a7a;
}

:root {
  /* Colors: */
  --unnamed-color-e2ad48: #e2ad48;
  --unnamed-color-707070: #707070;
  --unnamed-color-fafafa: #fafafa;
  ---3b3b3b: #3b3b3b;
  --unnamed-color-30a4dd: #30a4dd;
  --unnamed-color-8bc34a: #8bc34a;
  --unnamed-color-fe7400: #fe7400;

  /* Font/text values */
  --unnamed-font-family-roboto: Roboto, sans-serif !important;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: 600;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-size-15: 15px;
  --unnamed-font-size-17: 17px;
  --unnamed-font-size-19: 19px;
  --unnamed-font-size-24: 24px;
  --unnamed-font-size-28: 28px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-line-spacing-18: 18px;
  --unnamed-line-spacing-20: 20px;
  --unnamed-line-spacing-23: 23px;
  --unnamed-line-spacing-28: 28px;
  --unnamed-line-spacing-33: 33px;
}

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-19);
  line-height: var(--unnamed-line-spacing-23);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---3b3b3b);
}

.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-28);
  line-height: var(--unnamed-line-spacing-33);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---3b3b3b);
}

.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-17);
  line-height: var(--unnamed-line-spacing-20);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-fe7400);
}

.unnamed-character-style-4 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-15);
  line-height: var(--unnamed-line-spacing-18);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---3b3b3b);
}

.unnamed-character-style-5 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-19);
  line-height: var(--unnamed-line-spacing-23);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---3b3b3b);
}

.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-roboto);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-24);
  line-height: var(--unnamed-line-spacing-28);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(---3b3b3b);
}

.ExportButton {
  font-family: "Montserrat-Medium" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
}

.OrangeButton {
  background: var(--unnamed-color-fe7400) 0% 0% no-repeat padding-box !important;
  border: 2px solid var(--unnamed-color-fe7400) !important;
  background: #fe7400 0% 0% no-repeat padding-box !important;
  border: 2px solid #fe7400 !important;
  border-radius: 6px !important;
  opacity: 1;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-medium) var(--unnamed-font-size-15) /
    var(--unnamed-line-spacing-23) var(--unnamed-font-family-roboto) !important;
  letter-spacing: var(--unnamed-character-spacing-0) !important;
  text-align: left;
  font: normal normal medium 19px/23px Roboto;
  letter-spacing: 0px;
  color: #ffffff !important;
  width: auto;
  text-transform: capitalize !important;
  margin: 0 5px !important;
}

td {
  margin-left: 1%;
}

.newEditionInput {
  background: #ffffff;
  border: 1px solid #122526;
  height: 30px;
  width: 100%;
}

.newEditionInputText {
  font-family: "Montserrat-Light";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7a7a7a;
  width: 100% !important;
}

.newEditionLabel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.newEditionSubtitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.newEditionTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.newEditionBackLabel {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.tableTag {
  width: 100%;
  background: #ffffff;
  border: 1px solid #122526;
  overflow-x: auto;
}

.TableHeaderContent {
  background: #f2f5fb;
  padding: 10px;
}

.tHeader {
  padding: 10px;
  font-family: "Montserrat";
  font-size: 13px;
}

.tableTextLight {
  font-family: "Montserrat";
  font-size: 13px;
  padding: 10px;
  margin-bottom: 0px !important;
}

.tableTextBold {
  font-family: "Montserrat-Bold";
  font-size: 13px;
  padding: 10px;
  color: #3883e2;
  width: 150px;
  margin-bottom: 0px !important;
}

.tableTextBoldOrder {
  font-family: "Montserrat-Bold";
  font-size: 13px;
  padding: 10px;
  color: #3883e2;
  width: 120px;
  margin-bottom: 0px !important;
}

.tableBodyText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 10px 0px 10px 43px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(85% - 53px);
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

.tableHeaderText {
  font-family: "Montserrat-Medium";
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #000000;
  margin: 10px 33px;
}

.newEditionCardTitle {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

svg {
  overflow: visible;
}

.arrow {
  margin-left: 5px;
}

.rotatedArrow {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.collapsible {
  cursor: pointer;
  width: auto;
  border: none;
  text-align: left;
  outline: none;
  height: auto;
  background-color: #fff;
  border: none;
  padding: 0;
}

.MercadoPagoBox {
  max-width: 1000px;
  margin: 0 auto;
  border: 0.1px solid lightgray;
  margin-bottom: 20px;
}

.mercadopagoHeaderBox {
  display: grid;
  grid-template-columns: 30% 70%;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  grid-gap: 20px;
  background: #f2f5fb;
}

.mercadoPagoHeadeTextPillars {
  grid-template-columns: auto auto auto auto;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 20px;
  text-align: left;
}

.mercadopagoHeaderTitle {
  font-family: "Montserrat-Medium";
  font-size: 18px;
  margin-bottom: 20px;
}

.mercadoPagoHeaderPilarText {
  font-family: "Montserrat";
  font-size: 14px;
  color: #3883e2;
}

.mercadoPagoHeadeTextPillar img {
  margin-right: 10px;
}

.mercadoPagoHeadeTextPillar {
  display: inline-flex;
  max-width: 150px;
}

.mercadoPagoheaderTextLogoSize {
  width: 40px;
}

.mercadoPagoContentBox {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 30px;
  grid-gap: 20px;
}

.mercadoPagoInputsBox {
  display: grid;
  grid-template-columns: 10% auto 15% auto;
  margin: 0 auto;
  padding: 30px;
  grid-gap: 20px;
}

.mercadoPagoContentTitle {
  font-family: "Montserrat-Medium";
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 30px;
}

.mercadoPagoContentBoxTitles p {
  font-family: "Montserrat";
  font-size: 15px;
}

.porcentageNumber {
  color: #3883e2;
  font-family: "Montserrat";
  font-size: 15px;
}

.mercadoPagoFooterContent {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 30px;
  grid-gap: 20px;
}

.mercadoPagoHeaderTitleContainer {
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 0 auto;
  grid-gap: 20px;
}

.mercadopagoFotterButtonBlank {
  color: #3883e2;
  font-family: "Montserrat";
  font-size: 15px;
}

.mercadoPagoFotterSafePayLabel {
  font-family: "Montserrat";
  font-size: 15px;
}

.mercadoPagoButtonConfig {
  font-family: "Montserrat";
  font-size: 15px;
  background: white;
  color: #122526;
  padding: 10px;
  width: 200px;
  border: 1px solid #122526;
}

.mercadoPagoButtonConfig:hover {
  font-family: "Montserrat";
  font-size: 15px;
  background: #122526;
  color: white;
  padding: 10px;
  width: 200px;
  background: #122526;
  border: none !important;
}

.borderRight {
  border-right: 1px solid lightgray;
}

.MercadoPagoBoxInformation {
  max-width: 1000px;
  margin: 0 auto;
  border: 0.1px solid lightgray;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.mercadoPagoInformationText {
  font-family: "Montserrat";
  font-size: 13px;
  width: 700px;
}

.mercadoPagoInformationTextAccount {
  font-family: "Montserrat-Medium";
  font-size: 14px;
}

.mercadoPagoButtonCreateAccount {
  font-family: "Montserrat";
  font-size: 15px;
  background: white;
  color: #122526;
  padding: 10px;
  width: 300px;
  border: 1px solid #122526;
}

.buttonLogoMp {
  width: 50px;
}

.borderDivider {
  width: 80%;
  margin: 0 left;
  border-bottom: 0.1px solid darkgray;
  margin-top: 20px;
  margin-bottom: 20px;
}

.stateMercaPagoOn {
  color: #00ba00;
  font-family: "Montserrat-Medium";
  font-size: 16px;
}

.stateMercaPagoOff {
  color: black;
  font-family: "Montserrat-Medium";
  font-size: 16px;
}

.stateTextMercadoPago {
  font-family: "Montserrat-Medium";
  font-size: 14px;
}

.stateOffMargin {
  margin-right: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: rgb(0 0 0 / 40%);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dfdfdf;
}

.circle-picker {
  width: 100% !important;
  max-height: 250px;
  overflow-y: auto;
  padding: 5px;
  margin: auto;
  justify-content: left;
}

.circle-picker > span > div > span > div {
  border: 2px solid #000;
}

.column {
  display: flex;
  flex-direction: column;
}

.column.center {
  align-items: center;
}

.column.middle {
  justify-content: center;
}

.column.space-between {
  justify-content: space-between;
}

.rowNotBootstrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rowNotBootstrap.space-between {
  justify-content: space-between;
}

.row.middle {
  align-items: center;
}

iframe {
  pointer-events: none;
}
