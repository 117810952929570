.drawerPaper {
    display: flex;
    position: relative !important;
    white-space: break-spaces;
    transition: 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
    width: 230px;
    background-color: #FFFFFF !important;
    /* border: 1px solid #000000; */
}

.drawer {
    width: 230px;
    flex-shrink: 0;
}

.collapsible {
    cursor: pointer;
    padding: 10px 2px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    height: auto;
    background-color: #fff;
    margin: 0 0 15px 0;
}

.componentCollapsible {
    cursor: pointer;
    padding: 5px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    height: auto;
    background-color: #fff;
    margin: 0;
    min-height: 30px;
    max-height: 30px;
}


.subcomponent {
    cursor: pointer;
    padding: 5px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    height: auto;
    background-color: #fff;
    margin: 0;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.collapsible:hover,
.componentCollapsible:hover,
.subcomponent:hover {
    background-color: rgb(236, 236, 236) !important;
}

/* Style the collapsible content. Note: hidden by default */
.content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    width: 100%;
}

.subcontent {
    padding: 0 18px;
}


.titleCollapsible {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    margin: 0px 1px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.titleComponent {
    font-family: 'Montserrat-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    margin: 0px 3px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 1;
    line-clamp: 1; */
    -webkit-box-orient: vertical;
}

.titleSubcomponent {
    font-family: 'Montserrat-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    margin: 0px 1px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}


.noteText {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: black;
    width: 100%;
    text-align: justify;
    display: none;
}


.navBarTitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: #000000;
    height: 25px;
    padding: 5px;
    margin: auto;
}

.navBarTitle:hover {
    background-color: #e5e5e5;
}

.iconSpan {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}


.divider {
    width: 100%;
    height: 0px;
    border: 0.1px solid #e5e5e5;
    transform: rotate(0deg);
    border-radius: 5px;
    margin: 10px 0;
}

.templatesButton {
    border-radius: 0px !important;
    margin: 0px;
}

.selectedButton {
    color: #fff !important;
    background-color: #122526 !important;
}

.ButtonGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15px;
}

.selectedButton>.resolutionLabel {
    color: #fff;
}

.resolutionLabel {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 9.5px;
    text-transform: capitalize;
    margin: 0;
    padding: 0;
    text-align: left;
    color: #122526
}

.resolutionButton {
    min-width: 60px;
    padding: 5px !important;
    background-color: #fff;
    border: 0.632558px solid #122526;
}

.resolutionButton:hover {
    background-color: #d5d5d5;
}


.docked {
    height: 84vh;
    margin-top: 104px;
    overflow-x: hidden;
}

.configMarginLabel {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    padding: 0;
    margin: 0;
}

.configMarginButton {
    width: 100%;
    height: 44.85px;
    border-radius: 0 !important;
    background: #FFFFFF;
    border: 0.879479px solid #000000;
    margin: 10px 0;
    padding: 0;
}

.buttonGridContainer {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 5px;
    padding: 0px;
    width: 100%;
    justify-content: space-between;
}

.buttonGridContainer>div {
    padding: 0px;
    margin: 0;
}

.selectPageText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 28px;
    color: #000000;
}

.selectPageOptionSelected {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    outline: 0;
}

.selectPageOptionSelected div,
.select-selected {
    color: #e00000;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

.selectPageOption {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #000000;
    max-width: 50px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.newDynamicPageText {
    cursor: pointer;
    padding: 0px 5px;
}

.newDynamicPageText:hover {
    background-color: #e5e5e5;

}



.newDynamicPageResolutionButton {
    background: #122526;
    width: 100%;
    height: 120px;
    border: 0.3px solid white;
    margin: 25px 0px;
    cursor: pointer;
    display: grid;
    align-items: center;
}

.newDynamicPageResolutionButtonSelected {
    border: 1px solid #3883E2;
}

.newDynamicPageResolutionButton:hover {
    background: #e8e8e877;
}

.newDynamicPageResolutionButtonText {
    font-family: 'Montserrat-Light';
    font-size: 6px;
    line-height: 7px;
    text-align: center;
    color: #000000;
}

.newDynamicPageResolutionText {
    font-family: 'Montserrat-Light';
    font-size: 16px;
    text-align: center;
    color: white;
}

.imgButton {
    width: 35px;
    height: 35px;
    margin: 10px auto;
    display: flex;
}

.newDynamicPageResolutionButton:hover>.newDynamicPageResolutionText {
    color: #FFFFFF;
}