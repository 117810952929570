.root {
    min-height: 500px;
    height: auto;
    overflow-x: hidden;
    margin-top: -10px;
}

.itemDetailContainerSideCart {
    display: inline-flex;
}

strong {
    font-family: "Montserrat-bold";
}

.acceptOrderButtom {
    color: white;
    padding: 5px 10px;
    background: green;
     /* width: 120px; */
     width: 100%;
    font-family: "Montserrat-bold";
    font-weight: 700;
    border: unset !important;
    border-radius: 5px;
    height: 30px;
   
    cursor: pointer;
}
.fiscalDocumentLabel{
    color: red;
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    font-family: "Montserrat-Extrabold";

}
.acceptOrderButtom:hover {
    color: white;
    padding: 5px 10px;
    background: green;
     /* width: 120px; */
     width: 100%;
    font-family: "Montserrat-bold";
    font-weight: 700;
    border: unset !important;
    border-radius: 5px;
    height: 30px;
    margin-right: 20px;
    cursor: pointer;
    opacity: 0.5;
    text-decoration: none;
}

.acceptOrderButtom:disabled,
.rejectOrderButtom:disabled {
    cursor: inherit !important;
    opacity: 0.5 !important;
}

.rejectOrderButtom {
    color: white;
    padding: 5px 10px;
    background: #FF033E;
    /* width: 120px; */
    width: 100%;
    font-family: "Montserrat-bold";
    font-weight: 700;
    border: unset !important;
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
}

.rejectOrderButtom:hover {
    color: white;
    padding: 5px 10px;
    background: red;
    font-family: "Montserrat-bold";
    font-weight: 700;
    border: unset !important;
    border-radius: 5px;
    height: 30px;
    opacity: 0.5;
    cursor: pointer;
      /* width: 120px; */
      width: 100%;
}

.rejectReasonSelect {
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
    width: 100%;
}

.buttonsContainerOrderSideCart {
    text-align: center;
    display: grid;
    grid-gap: 19px;
    padding: 0 20px;
}

.buttonsContainerRejectOrderSideCart {
    display: inline-flex;
    align-items: center;
    
    width: 100%;
    align-items: flex-end;
    gap:5%

}
.containerRejectOrderSideCartSubContainerButtons {
    width: 30%;
}
.containerRejectOrderSideCartSubContainerLabelsInputs {
    width: 65%;
}
.labelRejectAceptOrder{
    margin-bottom: 5px;
    line-height: 15px;
    font-size: 12px;
    font-weight: 700;
}
.containersAcceptRejectOrder {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ItemsDetailsContainer {
    padding: 0px 20px;
    margin-top: -15px;
}

.itemDetailImageProduct {
    max-width: 70px;
    border-radius: 40px;
}

.orderCartContainer {
    display: inline-flex;
    align-items: center;
}

.orderCartIconSideBar {
    margin-right: 10px;
}

.orderInformation {
    padding: 20px;
}

details[open] summary~* {
    animation: sweep 1s ease-in-out;
}

@keyframes sweep {
    0% {
        opacity: 0;
        transform: translateX(-10px)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}

.cartGridContainer {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 5px;
    padding: 0px;
}

.cartGridContainer>div {
    padding: 0px 15px;
    margin: 0 auto;
}

.iconSideCartOrder {
    width: 75px;
    border-radius: 40px;
}


.cartTitle {
    height: 29px;
    margin: 15px 28px 22px 28px !important;
    font-size: 22px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.cartTitlePopover {
    height: 29px;
    margin: 15px auto 22px auto !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.cartTitleSidebar {
    height: 29px;
    width: 100%;
    margin: 15px auto 22px auto !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: center;
}

.orderTimeDate {
    font-family: "Montserrat-bold";
    color: steelblue;
    margin-top: 20px;
    margin-bottom: 0px;
}

.orderCartText {
    font-family: "Montserrat";
    font-size: 18px !important;
    line-height: 20px;
}

.listContainer {
    width: 100% !important;
    margin: 50px auto !important;
    padding: 15px 0 0 0 !important;
    background-color: #ffffff !important;
    min-height: 300px;
}


.notFountListContainer {
    width: 100% !important;
    margin: 50px auto 86px auto !important;
    padding: 15px 0 0 0 !important;
    background-color: #ffffff !important;
    min-height: 300px;
}

.notFountListContainerPopover {
    width: 95% !important;
    background-color: #ffffff !important;
    margin: 5px auto;
}

.bottomBorder {
    width: 95%;
    height: 1px;
    margin: 5px auto !important;
    border: 1px solid #979797;
    opacity: 0.3;
}


.imgTable {
    width: 212px !important;
    height: 212px !important;
    margin: 0 36px 18px 0 !important;
}

.imgTableCheckout {
    width: 100% !important;
    height: 100%;
    margin: 0 auto !important;
}

.imgTablePopover {
    width: 100% !important;
    max-width: 80px;
}

.ImgContainer {
    display: flex;
    position: absolute;
    left: 1%;
    top: 0;
}


.infoContainer {
    display: flex;
    position: relative;
    padding: 0 0 0 25px;
}

.paperPopover {
    width: 388px;
    height: 500px;
    overflow: hidden;
}

.paperSideBar {
    height: 100%;
    overflow: auto;
    width: 450px;
    min-height: 600px;

}

.notFoundPaperPopover {
    height: 100% !important;
}

.productTitlePopover {
    height: auto;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: left;
    overflow: hidden;
    margin-bottom: 5px;
    white-space: break-spaces !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
}

.productBrandPopover {
    height: auto;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: left;
    white-space: break-spaces !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    width: 100%;
}

.productTitle {
    width: 100%;
    height: 20px;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.productTitleCheckout {
    width: 100%;
    height: 20px;
    font-size: 15px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.sideBarRoot {
    max-width: 230px;
}


.productBrand {
    width: 100%;
    height: auto;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.productBrandCheckout {
    width: 100%;
    height: auto;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.productColor {
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.productQuantityCheckout {
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.removeContainer {
    display: flex;
    position: relative;
    right: 100%;
    bottom: 0;
    width: 100%;
    height: 25px;
}

.productColorPopover {
    font-size: 12px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    margin: auto 0 !important;
}

.productTalla {
    margin: 2px 0 8px !important;
    font-size: 14px;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    font-size: 11px !important;
}

.productQuantityValueCheckout {
    font-size: 15px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.productTallaPopover {
    margin: 2px 0 8px;
    font-size: 11px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-transform: capitalize;
}

.productPriceCheckout {
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
    color: #ff0000 !important;
    text-align: right;
}

.productPrice {
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
    color: #ff0000 !important;
}

.productPricePopover {
    font-size: 11px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
    color: #ff7500 !important;
    text-align: left;
}


.discountProductPrice {
    color: #717171 !important;
}

.normalProductPrice {
    color: #000 !important;
}

.discountProductPricePopover {
    color: #717171 !important;
    text-decoration: line-through;
    font-size: 13px !important;
}

.normalProductPricePopover {
    color: #000 !important;
    font-size: 16px !important;
}

.totalProducts {
    height: 29px;
    font-size: 22px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    margin: 0 0 0 10px !important;
}

.totalContainer {
    width: 100%;
    height: auto;
    margin: 50px auto !important;
    padding: 33px 0 0;
    /* box-shadow: 0 2px 14px 0 rgba(171, 171, 171, 0.5); */
    background-color: #ffffff !important;
}


.checkoutTotal {
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    width: 100%;
    height: auto;
    /* margin: 10px 212px 10px 10% !important; */
}

.checkoutTotalValue {
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    width: 100%;
    height: auto;
}

.checkoutTotalPopover {
    width: 100%;
    height: 24px;
    margin: 0 55.5px 2px 0px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.TotalTitleSidebar,
.TotalSidebar {
    width: 100%;
    height: 24px;
    margin: 0 55.5px 2px 15px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.TotalSidebar {
    width: auto;
    margin: 0 30px 2px 0 !important;
}

.bottomContainer {
    display: flex;
    position: absolute;
    bottom: 2%;
}

.totalInfoContainer {
    padding: 0 8%;
}

.checkoutInfo {
    width: 100%;
    height: auto;
    margin: 10px 0 !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.checkoutInfoPopover {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 15px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
}

.checkoutActions {
    height: auto !important;
    margin: 0px 0 0 !important;
    padding: 27px 0px 33px 0px !important;
    background-color: rgba(0, 0, 0, 0.05);
}

.checkoutActionsPopover {
    height: auto !important;
    margin: 0px 0 0 !important;
    padding: 5px 23px 33px 0px !important;
}

.nextButtonTitle {
    height: 21px;
    /* margin: 1.5px 0 1.5px 9px !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #ffffff !important;
}

.nextButton {
    width: 90%;
    height: 51px;
    padding: 13.5px !important;
    background-color: #000000 !important;
    border-radius: 0px !important;
    margin: 15px auto 0 auto !important;
}

.nextButton:hover {
    background-color: #000000e3 !important;
}

.nextButtonContainer {
    justify-content: center;
    display: flex;
    padding: 0;
}

.backButton {
    width: 90%;
    height: 51px;
    background-color: transparent !important;
    border: none !important;
    margin-top: 5px;
}

.backButtonTitle {
    height: 21px;
    /* margin: 18px 25.5px 0 55.5px !important; */
    font-size: 16px !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #ff7500 !important;
}

.deleteButton {
    margin-top: 2%;
    margin-right: 5px;
    height: 27px;
    font-size: 13px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
    color: #717171 !important;
    padding: 0px !important;
}

.deleteButton:hover {
    color: #ff7500 !important;
}

.notFoundTitle {
    height: 29px;
    margin: 25px auto 22px auto !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: center !important;
}

.notFoundText {
    height: 29px;
    margin: 25px auto 22px auto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: center !important;
}

.notFoundTextPopover {
    height: 50px;
    width: 100%;
    margin: 22px auto 22px auto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: center !important;
}

.notFoundTextSidebar {
    margin: 22px auto 22px auto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-align: center !important;
}

.selectTag {
    border: 1px solid #b1b1b1;
    margin: 5px;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    border-radius: 4px;
    text-transform: capitalize;
    width: auto !important;
}

.selectOption {
    border-radius: 4px;
    border: 1px solid #b1b1b1;
}

.Oval {
    width: 33px;
    height: 33px;
    margin: 0px -5px 1px 0px !important;
}

.OvalCopy {
    width: 20px;
    height: 20px;
    color: #fff;
}

.blackOvalCopy {
    color: #000 !important;
}

.OvalBadge {
    width: 8px;
    height: 8px;
    margin: 2px 0 3px;
    background-color: #ff5900;
}

.popoverRoot {
    width: 400px;
}

.descriptionPopover {
    height: auto;
    padding: 0 !important;
    text-align: left;
    margin-left: 0px;
    display: flex;
}

.priceDescriptionPopover {
    height: auto;
    padding: 0 !important;
    text-align: left;
    margin-left: 0px;
}

.cartListPopover {
    overflow-y: auto;
    overflow-x: hidden;
    height: 59%;
}

.cartListSidebar {
    overflow-y: auto;
    overflow-x: hidden;
    height: 98vh;
    padding: 5px;
}

.orderCartGeneralContainerBox {
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}

.orderCartGeneralContainerBox:hover {
    opacity: 0.8;
}

.detailsContainer {
    border-bottom: 0.1px solid black;
    z-index: 2000;
    margin-bottom: 30px;
}

.itemDetailNameSideCart {
    font-family: "Montserrat-bold";
    font-size: 15px;
    margin-right: 10px;
}

.itemDetailNameSideCartExtra {
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 700;
    margin-right: 10px;
    margin-bottom: 0px !important;
}

.detailsTitleText {
    font-family: 'Montserrat-Bold';
    text-align: center;
    font-size: 20px;
    text-decoration: underline;
}

.margin {
    margin: 0 5px !important;
}

title {
    font-style: italic !important;
}

.gridContainer {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-gap: 5px;
    padding: 0px 10px;
}

.gridContainer>div {
    padding: 0px 0;
    margin: 0 auto;
}


@media (min-width: 0px) and (max-width: 575px) {
    .cartGridContainer {
        grid-template-columns: auto;
    }

    .gridContainer {
        grid-template-columns: 25% 45% 30%;
    }

    .gridContainer>div {
        padding: 0px 0;
        margin: 0 auto;
    }

    .listContainer {
        width: 80% !important;
        margin: 50px auto 86px auto !important;
    }

    .totalContainer {
        width: 85% !important;
        margin: 50px auto 148px auto !important;
    }

    .nextButton {
        padding: 0px !important;
    }

    .imgTable {
        margin: 0 0px 18px 0 !important;
        width: 180px !important;
        height: 180px !important;
    }

    .popoverRoot {
        width: 90%;
    }

    .paperPopover {
        width: 250px;
    }

    .cartTitlePopover {
        font-size: 16px !important;
    }
    .paperSideBar {
        height: 100%;
        min-height: 600px;

        overflow: auto;
        width: 100%;
    }
    .containerButtons {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
}


/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767px) {
    .cartGridContainer {
        grid-template-columns: auto;
    }

    .listContainer {
        width: 70% !important;
        margin: 50px auto 86px auto !important;
    }

    .totalContainer {
        width: 70% !important;
        margin: 50px auto 148px auto !important;
    }

    .nextButton {
        padding: 0px !important;
    }

    .productTitlePopover {
        font-size: 14px !important;
    }

    .productBrandPopover {
        font-size: 13px !important;
    }

    .popoverRoot {
        width: 90%;
    }

    .paperPopover {
        width: 300px;
    }

    .cartTitlePopover {
        font-size: 17px !important;
    }

    .discountProductPricePopover {
        font-size: 11px !important;
    }

    .normalProductPricePopover {
        font-size: 14px !important;
    }
    .paperSideBar {
        height: 100%;
        min-height: 600px;
        overflow: auto;
        width: 450px;
    }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991px) {
    .cartGridContainer {
        grid-template-columns: auto;
    }

    .discountProductPricePopover {
        font-size: 13px !important;
    }

    .normalProductPricePopover {
        font-size: 14px !important;
    }

    .productBrandPopover {
        font-size: 16px !important;
    }

    .listContainer {
        width: 90% !important;
        margin: 50px auto 86px auto !important;
    }

    .totalContainer {
        width: 90% !important;
        margin: 50px auto 148px auto !important;
    }



    .popoverRoot {
        width: 400px;
    }

    .paperPopover {
        width: 388px;
    }

    .cartTitlePopover {
        font-size: 20px !important;
    }

    .descriptionPopover {
        margin-left: 0px !important;
    }
}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199px) {
    .productTitlePopover {
        margin-left: 0px !important;
        text-align: left;
    }

    .popoverRoot {
        width: 400px;
    }

    .paperPopover {
        width: 388px;
    }

    .descriptionPopover {
        margin-left: 0px !important;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .productTitlePopover {
        margin-left: 0px !important;
        text-align: left;
    }

    .popoverRoot {
        width: 400px;
    }

    .paperPopover {
        width: 388px;
    }

    .cartTitlePopover {
        font-size: 21px !important;
    }

    .descriptionPopover {
        margin-left: 0px !important;
    }
}