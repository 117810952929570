@import "~@fortawesome/fontawesome-free/css/all.css";
@import "./set1.css";
@import "./set2.css";
@import "./hover.css";
@import "./normalize.css";
@import "./components/web/home/pdp.css";
@import "~react-quill/dist/quill.snow.css";

/* html {
    scroll-behavior: smooth;
} */
.calendly-popup {
  max-width: none !important;
  height: 100% !important;
  width: 100% !important;
}
iframe {
  pointer-events: none;
}
.calendly-overlay > html > body > #page-region {
  color: #000;
}

.calendly-overlay iframe #page-region > div > div {
  padding: 0;
}

.calendly-overlay iframe #page-region > div > div > div {
  min-height: 500px;
  height: 500px;
}

.dynamicFont {
  font-family: "dynamicFont" !important;
  /* margin: var(--dynamic-margin);
    padding: var(--dynamic-padding);
    font-weight: var(--dynamic-font-weight);
    letter-spacing: var(--dynamic-letter-spacing);
    color: var(--dynamic-color); */
}

:root {
  --dynamic-margin: 0px;
  --dynamic-padding: 0px;
  --dynamic-font-weight: 400;
  --dynamic-letter-spacing: normal;
  --dynamic-color: #000;
}

@font-face {
  font-family: "OfficinaSans";
  src: url("./assets/fonts/OfficinaSansITC.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}

.gm-style .gm-style-iw-d {
  overflow: inherit !important;
}

.animation {
  background: #fbfdff;
}

.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
.intermitente {
  animation: infinite resplandorAnimation 3s;
}
@keyframes resplandorAnimation {
  0%,
  100% {
    /* border-color: inherit; */
  }
  50% {
    border-color: #cccbcb;
  }
}
@-moz-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes parpadeo {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
