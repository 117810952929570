.SearchInput {
    border-radius: 0px !;
    opacity: 1;
    height: 48px !important;
    width: 100%;
    font-family: 'Montserrat-Medium' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #7A7A7A;
    background: #FFFFFF;
    border: 1px solid #122526;
}



.option {
    background-color: #fff !important;
}

.option:hover {
    background-color: #7ba4d53e !important;
    cursor: pointer;
    color: #fff !important;
}

.selectedOption {
    background-color: #526d8d !important;
    color: #fff !important;
}

.MenuItem {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 3px !important;
    color: #000000;
}

.paper {
    width: 100% !important;
}