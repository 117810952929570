

/* NEW STYLES */


.backButton{
    border: none;
    background-color: white;
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat-Bold";
    align-self: flex-start;
    padding: 0;
}

.infoContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 50px;
}



.imageElement{
    width: 200px;
}

.infoDataContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}


.textDescription{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat-Light';
    padding: 0;

}
.inputStackContainer{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;

}



.inputElement{
    padding: 10px;
}










   /* NEW STYLES */

   .parentContainer{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 1px, rgba(0, 0, 0, 0.25) 0px 0px 2px;
    opacity: 1;

}





   .titleLogoContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


   .containerLogo{

   }
   .imageElement{
    width: auto;
    height: 50px;
   }

   .serviceStatusContainer{
display: flex;
align-items: center;
   }

 

.infoItemContainer{
    display: flex;
    flex-direction: column;
    gap: 5px;
}


.infoItemTitle{
    margin: 0;
font-size: 14px;
font-weight: 700;
font-family: "Montserrat-Bold";
}
   .infoItem{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat-Light";
}


.buttonConfig{
    padding: 10px;
    border: 1px solid black;
    background-color: white;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat-Medium";

}


.buttonsContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}
.confirmButton{
    background-color: #16b;
     background-image: linear-gradient(#2d8cec, #16b);
     border-radius: 4px;
     border: 1px solid #16b;
     margin: 0px;
     font-family: "Montserrat-Medium";
    
       font-weight: 700;
       font-size: 14px;
       line-height: 24px;
       color: white;
       padding: 10px;
       text-align: center;
       vertical-align: middle;
       cursor: pointer;
}

.cancelButton{
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    margin: 0px;
    font-family: "Montserrat-Medium";
   
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: black;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;

}
.deleteButton{
    background-color: white;
     border-radius: 4px;
     border: 1px solid red;
     margin: 0px;
     font-family: "Montserrat-Medium";
    
       font-weight: 700;
       font-size: 14px;
       line-height: 24px;
       color: red;
       padding: 10px;
       text-align: center;
       vertical-align: middle;
       cursor: pointer;
}



   .confirmButton:focus, .confirmButton:hover,   .cancelButton:focus, .cancelButton:hover,   .deleteButton:focus, .deleteButton:hover {
     opacity: 0.6;
   }
   .confirmButton:disabled, .confirmButton:disabled,   .cancelButton:disabled, .cancelButton:disabled,   .deleteButton:disabled, .deleteButton:disabled  {
    cursor: not-allowed;
    opacity: .5;
   }


   .configSettingsContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
   }
   .configSettingsTitle{
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: "Montserrat-Bold";
   }

   .configSettingsText{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Montserrat-light";
   }

   .inputContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    align-items: center;
}
.labelInput{
    font-weight: 700;
    font-size: 14px;
    font-family: 'Montserrat-Bold';
    margin: 0;
    width: 40%;

}