.MuiAlert-action {
  margin-bottom: auto;
}

.MuiAlert-root {
  font-family: "Blacker Display Light"
}

.MuiAlertTitle-root {
  font-weight: 600;
}

.details {
  text-transform: unset !important;
}

.text {
  color: black !important;
  text-transform: unset !important;
}