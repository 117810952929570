.container-margin {
    margin-top: 50px;
}

.container-right-details {
    padding: 76px 49px 30px 47px;
    /* padding-left: 250px !important; */
}

.quote-price {
    color: #ff6500 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
}

.descriptionPage {
    font-size: 21px !important;
    margin-top: 29px;
    padding: 20px 0px 20px 0px;
    text-align: justify;
}

.colors-title {
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: #000000 !important;
    text-transform: uppercase;
    font-size: 14px !important;

}

.colors-container {
    display: inline-flex;
    width: 100%;

}

.picturepdp {
    width: 100%;
    height: 720px;
    margin-top: 40px;
}

.colors-container span {
    margin: 12px 21px 18px 0px;
}

.info-container {
    display: inline-flex;
}

.info-container span {
    margin: 12px 15px;
    font-size: 16px;
}

.info-container span:hover {
    font-weight: 700;
    border-style: solid;
    border-color: #979797;
    border-width: 0px 0px 1px 0px;
    cursor: pointer;
}

.active-button {
    font-weight: 700;
    border-style: solid;
    border-color: #979797;
    border-width: 0px 0px 1px 0px;
    cursor: pointer;
}

.size-selected {
    background-color: #ececec;
    border-radius: 23px;
    width: auto;
    height: 30px;
    margin-left: -1px;
    font-size: 12px;
    padding: 6px 10px;
    font-weight: 700;
}

.size {
    border-radius: 23px;
    min-width: 40px;
    height: 40px;
    margin-left: -1px;
    font-size: 12px;
    padding: 15px;
    color: rgb(0 0 0) !important;
}

.price-product-normal {
    color: #717171 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
}

.product-attribute {
    color: #000 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
}

.price-discount {
    font-weight: 700;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.62 !important;
    letter-spacing: normal !important;
}

.border-size {
    border: solid 1px #979797;
    width: 100%;
    margin-bottom: 31px;
    margin-top: 12px;
}

.border-size-2 {
    border: solid 1px #979797;
    width: 100%;
    margin-bottom: 31px;
    margin-top: 22px;
}

.add-to-cart-button {
    width: 200px;
    height: 41px;
    font-size: 13px !important;
    margin-top: 14px;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    text-align: center !important;
    padding: 13px;
}

.wine-color {
    color: transparent;
    border-radius: 13px;
    height: 20px;
    background-color: #3c1919;
    width: 20px;
    cursor: pointer;
}

.wine-color:hover {
    opacity: 0.2;
}

.red-color:hover {
    opacity: 0.2;
}

.green-color:hover {
    opacity: 0.2;
}

.color:hover {
    opacity: 0.2;
}

.hover:hover {
    background-color: #ececec;

    cursor: pointer;
}

.color-selected {
    border: 2px solid #a9a9a9 !important;
}

.color {
    color: transparent;
    border-radius: 13px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border: 1px solid #0000007a;
}

.red-color {
    color: transparent;
    border-radius: 13px;
    height: 20px;
    background-color: #791919;
    width: 20px;
    cursor: pointer;
}

.green-color {
    color: transparent;
    border-radius: 13px;
    height: 20px;
    background-color: #2c685a;
    width: 20px;
    cursor: pointer;
}


.productBrand {
    font-size: 26px !important;
    font-weight: 600;
    margin-bottom: 0px;
}

.productTitle {
    font-size: 24px !important;
}

.stars {
    margin: 5px;
    font-size: 19px !important;
    color: #4d4d4d;
}

.magnifying-glass.visible {
    opacity: 1 !important;
    z-index: 1000 !important;
}

.container-left-details {
    padding: 0 35px !important;
}




@media (min-width: 0px) {}

/* // Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {


    .container-left-details {
        padding: 0 20px !important;
    }

    .container-right-details {
        padding: 76px 49px 30px 47px;
        /* padding-left: 250px !important; */
    }
}

/* // Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .container-left-details {
        padding: 0 35px !important;
    }

    .container-right-details {
        padding: 76px 49px 30px 0px;
        /* padding-left: 250px !important; */
    }

}

/* // Large devices (desktops, 992px and up) */

@media (min-width: 992px) {}

/* // Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}