.toolbar {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-gap: 5px;
    padding: 0px !important;
    margin: 0px;
    justify-content: space-between;
}

.toolbar>div {
    padding: 0px;
}

.navContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    padding: 0px;
}

.navContainer>div {
    padding: 0px;
    margin: auto 0;
}


.informationContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 5px;
    padding: 5px;
}

.informationContainer>div {
    padding: 0px;
    margin: auto;
}

.goBack {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 24px; */
    color: #FFFFFF;
    margin: 0;
}

.verticalDivider {
    width: 0px;
    height: 30.02px;
    border: 1.00052px solid #FFFFFF;
    margin: 0 15px;
}


.name {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    display: inline-flex;
    margin: 0;
    padding: 0;
}

.arrowIcon {
    margin: 0 20px;
}

.informationLabel {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}

.tutoToolbar {
    position: absolute;
    height: 40px !important;
    min-height: 40px !important;
    background: #647DEE;
    justify-content: center;
}

.tutoLink {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    text-align: center;
    padding: 0;
    margin: 0;
}