.tabIcon {
    width: 16px;
    height: 16px;
    margin: auto;
}

.label {
    font-size: 10px;
    color: hsla(0, 0%, 100%, .816);
    margin: 25px 0 0 20px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'Montserrat'
}