.cart-btns {
  margin: 0px -6px 0px 0px;
}

.cart-btns>button {
  display: inline-block;
  width: calc(49% - 0px);
  padding: 3px;
  background-color: #122526;
  color: #fff;
  text-align: center;
  font-weight: 400;
  text-transform: capitalize;
  height: 35px;
}

.cart-btns>button:first-child {
  background-color: #797979;
}

.cart-btns>button:last-child:hover {
  background-color: #dc6603;
}

.cart-btns>button:first-child:hover {
  background-color: #484848;
}

#header-btn {
  float: left;
}

#cart {
  top: 2% !important;
  width: 300px;
}